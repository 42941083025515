<script>
export default {
    name: 'NavComponent',
}
</script>
<div style="height: 70px;"></div>
<template>
<nav class="navbar navbar-expand-lg navbar-light golden1 fixed-top ">
    <div class="container-xxl">
        <button class="order-lg-3 navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <a class="order-lg-1 navbar-brand" href="/" width="112px">
            <img src="@/assets/img/LOGO-i.png" class="center">
        </a>
        <div class="order-lg-5 d-lg-inline d-none">
            <a href="tel:+94761367821" class="link" style="text-decoration: none; color: #ffffff;">
                <div class="row white">

                    <div class="row  justify-content-end">
                        <div class="container contact">
                            <div class="row" style="width: 130px;">
                                <div class="col" style="min-width: fit-content;max-width: fit-content;padding-right: 6px;">
                                    <i class="fas fa-phone-alt fa-lg " style="min-width: 18px;"></i>
                                </div>

                                <div class="col " style="padding-left: 0px;padding-right: 0px;max-width: 142px;">

                                    <div>
                                        <h6 class="" style="margin-bottom: 0px; box-sizing: content-box; font-weight: bold;">
                                            Contact Us</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="num ">

                        +94 76 136 7821
                    </div>

                </div>
            </a>
        </div>
        <div class="order-lg-4 d-inline d-lg-none"> <a href="tel:+94761367821" class="link-dark"><i class="fas fa-phone fa-lg"></i></a></div>
        <div class=" order-lg-2 collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-1 mb-lg-0">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Book Library
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li class="dd"><a class="dropdown-item" href="/books/sudu-minissu-1">Sudu Minissu 1</a>
                        </li>
                        <hr>
                        <li class="dd"><a class="dropdown-item" href="/books/sudu-minissu-2">Sudu Minissu 2</a></li>
                        <hr>
                        <li class="dd"><a class="dropdown-item" href="/books/sudu-minissu-3">Sudu Minissu 3</a></li>
                        <hr>
                        <li class="dd"><a class="dropdown-item" href="/books/sudu-minissu-4">Sudu Minissu 4</a></li>
                        <hr>
                        <li class="dd"><a class="dropdown-item" href="/books/sudu-minissu-5">Sudu Minissu 5</a></li>
                        <hr>
                        <li class="dd"><a class="dropdown-item" href="/books/sudu-minissu-6">Sudu Minissu 6</a></li>
                        <hr>
                        <li class="dd"><a class="dropdown-item" href="/books/sudu-minissu-7">Sudu Minissu 7</a></li>
                        <hr>
                        <li class="dd"><a class="dropdown-item" href="/books/minis-sunakhaya">Minis Sunakhaya</a>
                        </li>
                        <hr>
                        <li class="dd"><a class="dropdown-item" href="/books/maw-senehasa">Maw Senehasa</a>
                        </li>
                        <hr>
                        <li class="dd"><a class="dropdown-item" href="/books/sarwajana-washiya">Sarwajana Washiya</a>
                        </li>
                        <hr>
                        <li class="dd"><a class="dropdown-item" href="/books/sapalath-mawkusa">Sapalath Mawkusa</a>
                        </li>
                        <hr>
                        <li class="dd"><a class="dropdown-item" href="/books/dam-senevi">Dam Senevi</a>
                        </li>
                        <hr>
                        <li class="dd"><a class="dropdown-item" href="/books/vilapaya">Vilapaya</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item d-none d-lg-block">
                    <div class="nav-link seperator">|</div>
                </li>
                <!-- <li class="nav-item ">
                        <a class="nav-link" href="#author" style="color: #ffffff;">Author</a>
                    </li>
                    <li class="nav-item d-none d-lg-block">
                        <div class="nav-link seperator">|</div>
                    </li> -->
                <li class="nav-item white">
                    <a class="nav-link white" href="/shops">Where to buy</a>
                </li>

            </ul>
        </div>

    </div>
</nav>
</template>

<style>
.golden1 {
    background-color: #FFC000;
}

.golden {
    color: #FFC000;
}

.white {
    color: white;
}

.white:hover {
    color: rgb(98 98 98);
}

hr {
    margin: .4rem 0;
}

.navbar-light .navbar-nav .nav-link.seperator {
    color: #a3a3a3;
}
</style>
